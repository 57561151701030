.react-flow__renderer {
  z-index: 2;
}

.scenario {
  height: 100vh;
  width: 100vw;
  background-color: var(--theme__bg-color);
  background-image: var(--theme__bg-img);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}


/* HUD */
.scenario-hud {
  padding-top: 2vh;
  padding-bottom: 2vh;
  position: absolute;
  top: 0;
  z-index: 6;
}

.hud {
  z-index: 5;
}

.hud__row__cell {
  display: flex;
  align-items: center;
  height: 100%;
  gap: .5em;
  font-weight: bold;
  font-size: 1.3em;
}

.hud__properties {
  position: absolute;
  top: 2em;

  width: 40vw;
  height: 4em;

  color: var(--gray);

  display: flex;
  justify-content: space-between;
  padding-right: 2em;

  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left-color: var(--gray-400);
}

.hud__properties--background {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;

  width: 100%;
  height: 80%;
}

.hud__properties--status {
  padding-left: 2em;
}

.hud__properties--status img {
  height: 100%;
}

.hud__properties--users img {
  height: 80%;
}

.hud__properties--time img {
  height: 75%;
}

.hud__configs {
  position: absolute;
  top: 2em;
  right: 0;

  width: 25vw;

  color: var(--gray);
  height: 4em;

  display: flex;
  justify-content: space-around;
  padding-left: 2em;

  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right-color: var(--gray-400);
}

.hud__configs--background {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;

  width: 100%;
  height: 100%;

  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.hud__configs--zoom img {
  height: 100%;
}

.hud__configs--help img {
  height: 100%;
}

.hud__configs--gear img {
  height: 100%;
}

.hud__configs-zoom-btn {
  position: absolute;
  width: 100%;
  height: 50%;
  cursor: pointer;
}

.hud__configs-zoom-btn--plus {
  top: 0;
}

.hud__configs-zoom-btn--minus {
  bottom: 0;
}

.hud__configs-action-btn {
  height: 100%;
}

.hud__actions {
  position: absolute;
  bottom: 2em;
  right: 2em;
}

.hud__actions--todo {
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  position: relative;
}

.hud__actions--todo img {
  width: 6em;
}

.hud__actions--analyse {
  display: flex;
  position: relative;
  cursor: pointer;
}

.hud__actions--analyse img {
  height: 6em;
}

.hud__actions--analyse-bg {
  position: absolute;
  right: 30px;
  z-index: -1;
}

.hud__actions--analyse-text {
  font-size: 30px;
  position: absolute;
  right: 118px;
  top: 19px;
  z-index: -1;
  color: white;
}

.hud__utils {
  position: absolute;
  bottom: 2em;
  left: 2em;

  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.hud__utils-icon {
  height: 60px;
  cursor: pointer;
  user-select: none;
}

.hud__utils-tree-wrapper {
  padding: 15px;
  max-width: 270px;
  background-color: #218CB7;
  color: white;
}

.hud__utils-tree-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  display: grid;
  gap: 15px;
}

.hud__utils-tree {
  height: 60px;
}

.hud__utils-tree.open {
  height: unset;

}

.hud__utils-tree.open img {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.hud__configs--gear {
  position: relative;
}

.scenario__color {
  color: var(--theme__color-primary);
}

.share-scenario__email {
  width: 100%;
  height: 50px;
}

.theme_input {
  border: 1px solid var(--theme__color-primary);
  outline: none;
}

.input {
  border: 1px solid var(--gray);
  outline: none;
}

.timeline__item {
  grid-template-columns: 50px 30% 25px 1fr;
}

.timeline__item-line {
  background-color: var(--theme__color-primary);
  height: 100%;
}

.hud__actors-list-wrapper {
  position: absolute;
  top: 60px;
  z-index: 4;
}

.sc__color {
  color: var(--theme__color-primary);
}

.sc-modal__file-img {
  position: relative;
  height: 30px;
  width: 20px;
  border: 1px solid var(--theme__color-primary);
}

.sc-modal__border {
  border: 3px solid var(--theme__color-primary);
}

.hud__utils-tree {
  position: relative;
}

.scenario-tree__menu {
  border-left: var(--sc__border);
  border-right: var(--sc__border);
  border-top: var(--sc__border);

  position: absolute;
  top: 0;
  left: -3px;
  transform: translateY(-100%);
  z-index: 4;

  padding: 10px 0;
  width: 100%;
  background-color: white;

  display: grid;
  gap: 10px;
}

.scenario-tree__menu-item {
  word-break: break-word;
  font-size: 14px;
  padding: 0 10px;
  text-align: center;
}

.scenario-to-do__menu {
  position: absolute;
  right: 10px;
  z-index: 4;
  transform: translateY(-103%);

  min-width: 250px;
  height: 300px;

  background-color: #218CB7;
  color: white;
  white-space: nowrap;

  display: grid;
  grid-auto-rows: max-content;
  gap: 10px;
}

.scenario-to-do__menu-title {
  border-bottom: 2px solid white;
  width: max-content;
  margin: 0 auto;
}

.scenario-to-do__menu-items-wrapper {
  max-width: 300px;
}

.scenario-to-do__menu-items {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

.scenario-to-do__menu-item {
  word-break: break-word;
  white-space: pre-wrap;
}

.hud__configs--undoRedo {
  display: flex;
  align-items: center;
}

.theme-color--primay {
  color: var(--theme__color-primary);
}

.theme-primary__stroke {
  stroke: var(--theme__color-primary);
}

.theme-primary__fill {
  fill: var(--theme__color-primary);
}