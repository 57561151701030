.node {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background-color: white;
  color: var(--theme__color-primary);
  border: 4px solid var(--theme__color-primary);
  text-align: center;
}

.node__risk-icon {
  --size: 90px;
  height: var(--size);
  width: 45px;
  position: absolute;
  left: -30px;
  bottom: 10px;
}

.node__content {
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.node__plus {
  position: absolute;
  right: -15px;
  top: 0;
  width: 30px;
  cursor: pointer;
}

.node__label {
  line-height: 1;
  word-break: break-all;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme__color-primary);
  width: 80%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}