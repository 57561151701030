:root {
  --fs: 16px;
  --fs-600: 18px;
}

.fs {
  font-size: var(--fs);
}

.fs-600 {
  font-size: var(--fs-600);
}