.tooltip .tooltip__content::after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: var(--theme__color-primary) transparent transparent transparent;

}

.tooltip.left {
  transform: translate(40%, -45%);
}

.tooltip.right {
  transform: translate(-115%, -45%);
}

.tooltip.top {
  transform: translate(-40%, -110%);
}

.tooltip.bottom {
  transform: translate(-40%, 50%);
}

.tooltip.top .tooltip__content::after {
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-color: var(--theme__color-primary) transparent transparent transparent;
}

.tooltip.bottom .tooltip__content::after {
  top: -20px;
  left: 50%;
  margin-left: -10px;
  border-color: transparent transparent var(--theme__color-primary) transparent;
}

.tooltip.right .tooltip__content::after {
  top: 50%;
  left: 100%;
  margin-top: -10px;
  border-color: transparent transparent transparent var(--theme__color-primary);
}

.tooltip.left .tooltip__content::after {
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-color: transparent var(--theme__color-primary) transparent transparent;
}