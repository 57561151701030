.block {
  display: block;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.ta--center {
  text-align: center;
}