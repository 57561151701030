.button {
  background-color: white;
  color: var(--gray);
  padding: 8px 14px;
  outline: none;
  /*box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.46);*/
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);

  font-size: var(--fs);
}

.button:hover {
  color: var(--gray-600);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.46);
}

.button--danger {
  color: var(--red);
}

.button--danger:hover {
  background-color: var(--red);
  color: var(--white);
}

.button.primary {
  background-color: var(--theme__color-primary);
  color: white;
}