.react-flow__node-CENTER_ELEMENT {
  z-index: 2 !important;
}

.center-element--wrapper {
  height: 670px;
  width: 670px;
  background-color: var(--theme__color-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 0;
}

.center-element {
  height: 306px;
  width: 306px;
  border-radius: 50%;
  color: black;
  text-align: center;
  position: relative;
}

.center-element__content-bg-img {
  z-index: 0;
  position: absolute;
  left: 0;
}

.center-element__content {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.center-element__label {
  display: grid;
  gap: 10px;
  width: 55%;
}

.center-element__label-primary {
  color: var(--theme__color-secondary);
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 14px;
}

.center-element__label-secondary {
  color: var(--sc__color);
  text-transform: uppercase;
  line-height: 1.1;

}

.center-element__divider {
  background-color: var(--theme__color-secondary);
  width: 30px;
  margin: 0 auto;
}

/* GUIDED MODAL */

.guided-modal {
  padding: 1em;
}

.guided-modal p {
  font-size: 14px;
}

.guided-modal__title {
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  color: var(--gray-600);
}

.guided-modal__sub-title {
  color: var(--gray-600);
}

.guided-modal__example {
  font-style: italic;
  margin-left: 2em;
  background-color: yellow;
  padding: 10px;
}

.guided-modal__inputs {
  height: 50px;
  width: 100%;
  margin-top: 1em;
  text-align: center;
}

.guided-modal__textarea {
  height: 100%;
  width: 100%;
  border: 1px solid var(--sc__color);
  resize: none;
}

.guided-modal__input {
  border: 1px solid var(--sc__color);
}

textarea.guided-modal__input {
  resize: none;
}