.auth__page {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  --primary: #846db1;
  --primary-light: #a791d3;
}

.auth__hero {
  height: 100%;
  background-color: var(--primary);
  color: white;

  display: grid;
  align-items: center;
  justify-content: center;
}

.auth__hero img {
  width: 100%;
  max-width: 526px;
}

.auth__hero .action {
  display: none;
  color: #fff;
  background: #333333;
  border: 1px solid #333333;
  border-radius: 2rem;
  padding: 0.5rem 2rem;
  width: max-content;
  cursor: pointer;
  user-select: none;
  font-size: 1.3rem;
  outline: none;
}

.auth__hero .action:hover {
  color: var(--primary-light);
}

.auth__hero-content {
  display: grid;
  justify-items: center;
  gap: 1.5rem;
}

.auth__form {
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}

.auth__form-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
}

.auth__form .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.auth__form-content {
  display: grid;
  gap: 1.2rem;
}

.auth__form-input {
  display: grid;
  gap: 4px;
}

.auth__form-input .label {
  font-weight: 700;
  display: block;
  font-size: .9rem;
}

.auth__form-input .required {
  color: #f44336;
  margin-left: 3px;
}

.auth__form-input .box {
  border: 1px solid #ced4da;
  padding: 0.4375rem;
  border-radius: 6px;
  outline-color: var(--primary);
}

.auth__form-secondary {
  font-size: .7rem;
  cursor: pointer;
  width: max-content;
}

.auth__form-secondary:hover {
  color: var(--primary);
}

.auth__form-btn {
  color: #fff;
  background: linear-gradient(#a68dda 0%, #a68dda .01%, #846db1 95.31%);
  border: 1px;
  border-radius: 2rem;
  padding: 0.5rem 2rem;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  user-select: none;
}

.auth__form-btn[disabled] {
  cursor: not-allowed;
}

.auth__form-next-action {
  font-size: .9rem;
  margin-top: 1.5rem;
  text-align: center;
}

.auth__form-next-action .click {
  font-size: .7rem;
  font-weight: 700;
  cursor: pointer;
}

.auth__form-next-action .click:hover {
  color: var(--primary);
}

.auth__footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.auth__footer .label {
  font-size: 0.8rem;
}

.auth__footer img {
  width: 100%;
  max-width: 250px;
}

.auth__header {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
}

.auth__header img {
  width: 100%;
  max-width: 90px;
}

.auth__header img.cotec {
  width: 100%;
  max-width: 140px;
}

.auth__header img.advantage {
  width: 100%;
  max-width: 140px;
}

.auth__form-language {
  position: absolute;
  top: 10px;
  left: 10px;

  cursor: pointer;
  font-size: 13px;
}

.auth__form-language-selected {
  border: 1px solid #ced4da;
  border-radius: 2rem;
  background-color: white;
  height: 30px;

  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  padding: 0px 8px;
}

.auth__form-language img {
  height: 15px;
}

.auth__form-language-popup {
  background-color: white;
  border-radius: 2rem;

  display: grid;
  gap: 5px;

  box-shadow: 0px 1px 4px 1px #ced4da;
  padding: 5px;
  margin-top: -37px;
  position: relative;
}

.auth__form-language-popup-option {
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  padding: 0px 8px;
}

/* MEDIA */
@media (max-width: 768px) {

  .auth__page {
    grid-template-columns: 1fr;
    gap: 2rem;
    overflow-y: auto;
  }

  .auth__hero {
    height: 100vh;
  }

  .auth__hero .action {
    display: block;
  }

  .auth__form {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }

}

@media (max-width: 992px) {
  .auth__hero {
    color: red;
  }
}

@media (max-width: 1200px) {
  .auth__hero {
    color: white;
  }
}