:root {
  --border-radius: 25px;
}

.border-radius {
  border-radius: var(--border-radius, 5px);
}

.border {
  border: 3px solid white;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  border: solid var(--gray);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow--up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  opacity: 0;
  transition: all .6s;
  pointer-events: none;
}

.ripple:active:after {
  width: 0px;
  height: 0px;
  opacity: 1;
  transition: 0s;
}

.menu__flag {
  height: 30px;
  width: 30px;
}

.underline__hover:hover {
  text-decoration: underline;
}