.scenarios__content {
  width: 80%;
  margin: 0 auto;
  padding-top: 6em;
}

.scenarios {
  display: grid;
  gap: 2em;
  max-height: 65vh;
  overflow-y: auto;
}

.scenarios__card {
  position: relative;
  cursor: pointer;
}

.scenarios__card-content {
  height: 175px;
  width: 278px;
  border-radius: 4px;

  background-image: url('../assets/scenario_card.png');
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.scenarios__card--new .scenarios__card-content {
  background-image: url('../assets/new_scenario.png');
}

.scenarios__card-label {
  color: var(--gray-600);
  font-weight: 700;
  word-break: break-word;
  width: 90%;
}

.scenarios__tree-title {
  font-size: 26px;
  font-weight: 700;
  color: white;
}

.scenarios__tree-items {
  overflow-x: auto;
  display: flex;
  gap: 10px;
  width: 100%;
}