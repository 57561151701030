.modal__wrapper {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, .3);
}

.modal {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .modal {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .modal {
    width: 680px;
  }
}

@media (min-width: 1200px) {
  .modal {
    width: 540px;
  }
}

/* TINY */
@media (min-width: 768px) {
  .modal.tiny {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .modal.tiny {
    width: 680px;
  }
}

@media (min-width: 1200px) {
  .modal.tiny {
    width: 540px;
  }
}

/* BIG */

@media only screen and (min-width: 768px) {
  .modal.big {
    width: 70.4%;
    margin: 0;
  }
}

@media only screen and (min-width: 992px) {
  .modal.big {
    width: 680px;
    margin: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .modal.big {
    width: 720px;
    margin: 0;
  }
}

.sc-modal__field {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 5px;
  flex: 1;
}

.sc-modal__input::placeholder {
  font-style: italic;
}

.sc-modal__input[type="number"] {
  border: 1px solid var(--gray);
}

.sc-modal__label {
  font-weight: 700;
  font-size: 16px;
  color: var(--gray);
}

.sc-modal__input {
  border: none;
  border-bottom: 1px solid var(--gray);
  outline: none;
  background-color: transparent;
  width: 100%;
}

.sc-modal__input-hint {
  margin-left: 5px;
  color: var(--gray);
}

.sc-modal__slider {
  -webkit-appearance: none;
  outline: none;
  height: 20px;
  width: 120px;
  --thumb-size: 20px;
}

.sc-modal__slider::-webkit-slider-runnable-track {
  background-image: url('../../assets/risk_slider.svg');
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleX(-1);
}

.sc-modal__slider::-webkit-slider-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position-y: 10px;
  opacity: var(--thumb-display);
}

.sc-modal__slider::-moz-slider-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  border: 0;
  cursor: pointer;
  background-repeat: no-repeat;
}

.sc-modal__slider.green::-webkit-slider-thumb {
  background-image: url('../../assets/risk_slider_thump--green.svg');

}

.sc-modal__slider.green::-moz-slider-thumb {
  background-image: url('../../assets/risk_slider_thump--green.svg');
}

.sc-modal__sliderV2 {
  -webkit-appearance: none;
  appearance: none;

  cursor: pointer;

  width: 200px;
  height: 10px;

  background: #e4e4e4;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 1), rgb(255, 217, 3), rgb(2 219 2));
  border-radius: 5px;
  outline: none;

  --thumb-size: 15px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.sc-modal__sliderV2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: 50%;
  background: var(--theme__color-primary);
  cursor: pointer;
  opacity: var(--thumb-display);
}

.sc-modal__sliderV2::-moz-range-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  background: var(--theme__color-primary);
  cursor: pointer;
  opacity: var(--thumb-display);
}

div svg {
  fill: var(--gray) !important;
}