.auth-page {
  background-color: lightgray;
  --mt: 10vh;
  margin-top: var(--mt);
  height: calc(100vh - var(--mt));
  background-image: url(../assets/login_bg.png);
  background-repeat: round;
}

.auth-page__title {
  margin-bottom: 30px;
  color: var(--gray-600);
  font-weight: 700;
  text-align: center;
  font-size: 50px;
  letter-spacing: 1.5px;
}

.auth-page__card {
  background-color: white;
  box-shadow: 1px 1px 6px 0px var(--gray-600);
  padding: 25px;
  width: 300px;
}

.auth-page__card-title {
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-600);
  font-weight: 700;
  font-size: 20px;
}

.auth-page__form {
  display: grid;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.auth-page__input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-page__input-icon {
  width: 20px;
}

.auth-page__input-element {
  width: 100%;
  border: 1px solid var(--gray);
  border-radius: 3px;
  padding: 2px 4px;
  height: 20px;
}

.login-page__btn {
  color: white;
  background-color: dodgerblue;
  padding: 8px 12px;
  cursor: pointer;
  outline: none;
  border: none;
  display: block;
}

.login-page__login-btn {
  margin: 0 auto 10px auto;
}

.auth-page__card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: var(--color-text);
}

.login-page__forgot-password {
  font-size: 13px;
}

.login-page__checkbox {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}

.login-page__register-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.login-page__register-label {
  font-size: 18px;
  color: var(--gray-600);
}

.login-page__register-btn {
  font-size: 16px;
  padding: 4px 6px;
  color: var(--gray-600);
  border: 3px dashed var(--gray-600);
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
}

.auth-button {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  /*border: 1px solid var(--color);*/
  border: 1px solid #ced4da;
  border-radius: 2rem;
  background-color: white;

  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.auth-button__text {
  color: black;
  font-weight: 600;
}

.auth-button__icon {
  height: 90%;
}

.auth-button__icon.small {
  height: 70%;
}

.version {
  position: absolute;
  bottom: 0;
  left: 15px;
  color: lightgray;
}