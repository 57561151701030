:root {

  --padding: 25px;

  --margin: 25px;

}

.padding {
  padding: var(--padding);
}

.margin-top {
  margin-top: var(--margin);
}

.mt-4 {
  margin-top: 1rem;
}