body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
  box-sizing: content-box;
}

:root {
  --color-dark: darkslategrey;
  --color-light: darkcyan;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, .9);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}


/* FONTS */
.fs--tiny {
  font-size: .68em;
}

.fs--small {
  font-size: .88em;
}

.fs--normal {
  font-size: 1em;
}

.fs--big {
  font-size: 1.5em;
}

.fs--large {
  font-size: 4em;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

/* COLOR */
.color--dark {
  color: var(--color-dark);
}

.color--white {
  color: white;
}

.bg--lightblue {
  background-color: lightblue;
}

.bg--white {
  background-color: white;
}

.bg--blue {
  background-color: dodgerblue;
}

/* ALGINMENT */
.align--center {
  text-align: center;
}

/* PADDING */
.pb--normal {
  padding-bottom: 1em;
}

.p--big {
  padding: 2em;
}

.pl--normal {
  padding-left: 1em;
}

.pl--big {
  padding-left: 2em;
}

.p-btn--normal {
  padding: 8px 12px;
}

/* MARGIN */
.mt--normal {
  margin-top: 1em;
}

.mt--big {
  margin-top: 2em;
}

.mt--small {
  margin-top: .88em;
}

.mb--normal {
  margin-bottom: 1em;
}

.mb--big {
  margin-bottom: 2em;
}

.mb--small {
  margin-bottom: 1em;
}

.m--center {
  margin: 0 auto;
}

/* BORDER */
.b--light {
  border: 2px solid gray;
}

.b--none {
  border: none;
}

.bc--light {
  border-color: var(--color-light);
}

.out--none {
  outline: none;
}

/* LAYOUT */
.vh--full {
  height: 100vh;
}

.ta--center {
  text-align: center;
}

.ai--center {
  align-items: center;
}

.ai--end {
  align-items: flex-end;
}


.ji--center {
  justify-items: center;
}

.jc--space-between {
  justify-content: space-between;
}

.jc--end {
  justify-content: flex-end;
}

.jc--center {
  justify-content: center;
}

.gap--normal {
  gap: 10px;
}

.gap--small {
  gap: 6px;
}

.gap--big {
  gap: 20px;
}

.gap--large {
  gap: 30px;
}

.gap--huge {
  gap: 40px;
}

/* CURSOR */
.pointer {
  cursor: pointer;
}

button:disabled {
  pointer-events: none;
  opacity: .6;
  user-select: none;
}

/* WIDTH */

.max-content {
  width: max-content;
}

.w100 {
  width: 100%;
}

/* POSITION */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}


/* DIVIDER */
.divider {
  height: 3px;
  background-color: gray;
  border-radius: 5px;
}

.divider--horizontal {
  width: 100%;
}

.full-page {
  height: 100vh;
  width: 100vw;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  opacity: 0;
}

.thick-tooltip {
  border-width: 3px !important;
}

.sc_modal-dimmer {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.sc_modal-content {
  background-color: white;
  border: 3px solid var(--sc__color);
  padding: 25px;
  border-radius: 5px;
}

.no-click {
  user-select: none;
  cursor: not-allowed;
}

.bold___hover:hover {
  font-weight: 700;
}

.color--primary {
  color: var(--sc__color);
}

.default-modal__content {
  background-color: #e3e3e3;
  border-radius: 5px;
  border: 2px solid white;
}