:root {

  --gray-400: rgb(226, 226, 226);
  --gray: rgb(134, 134, 134);
  --gray-600: rgb(113, 113, 113);

  --blue-400: #81b7cc;
  --blue: rgb(33, 140, 183);

  --white: white;

  --red: red;
}

.color--gray {
  color: var(--gray);
}

.bg-color--gray {
  background-color: var(--gray);
}

.color--gray-400 {
  color: var(--gray-400);
}

.bg-color--gray-400 {
  background-color: var(--gray-400);
}

.color--gray-600 {
  color: var(--gray-600);
}

.bg-color--gray-600 {
  background-color: var(--gray-600);
}

.color--blue-600 {
  color: var(--blue-600);
}

.bg-color--blue-600 {
  background-color: var(--blue-600);
}

.color--blue-400 {
  color: var(--blue-400);
}

.bg-color--blue-400 {
  background-color: var(--blue-400);
}

.color--white {
  color: var(--white);
}

.bg-color--white {
  background-color: var(--white);
}

.color--red {
  color: var(--red);
}

.bg-color--red {
  background-color: var(--red);
}