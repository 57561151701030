.react-flow__edges {
  z-index: 2;
}

.edge {
  stroke: var(--theme__color-primary);
  fill: var(--theme__color-primary);
  stroke-width: 3;
  stroke-linecap: round;
}

.edge-arrow {
  stroke: var(--theme__color-primary);
  fill: var(--theme__color-primary);
}

.edge-add {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
}

.edge-add__plus {
  cursor: pointer;
  width: 23px;
}